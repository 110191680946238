
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import dayjs from 'dayjs'
import qrCodeRender from './qr-code-render.vue'

@Component({
    components: {
      qrCodeRender
    },
    computed: {
    ...mapState({
      storeInfo: (state: any) => state.Store.storeInfo,
      storeSetting: (state: any) => state.Store.storeSetting
    }),

    ...mapGetters({
      branchAddress: 'Store/branchAddress'
    })
  }
})
export default class Receipt extends Vue {
  gwStoreId = '5e3548c2d32cb12606a34fb8'

  storeInfo!: any

  storeSetting!: any

  branchAddress!: any

  formatDateTime!: any

  @Prop() order!: any

  @Prop() isPreview!: boolean

  get webUrl (): any {
    return this.storeSetting?.webUrl || ''
  }

  sumAmount = (items: Array<any>): any => items.reduce((sum: number, d) => sum + d.amount, 0)

  priceStepPricing = (items: Array<any>): any => items.reduce((sum: number, d) => sum + d.price, 0)

  showFullPriceFormat = (number: number): any => ((number).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  calPriceBeforeVat = (price: number): number => (price * 100) / 107

  salesViaHeadquarter = (
    // eslint-disable-next-line
    this.order.branchPos.unitId == 1117 || this.order.branchPos.unitId == 1143
  )

  showProductName = (text: string): any => {
    const a = text.split('-')
    if (a[0].length > 25) {
      return [`${a[0].substring(0, 24) }...`, a[1]]
    }
    return [a[0], a[a.length - 1]]
  }

  get previousPaid (): any {
    const previousData = { isCreditNote: false, sum: 0 }
    if (!this.order) return previousData
    if (this.order.payments.length > 1) {
      const beforeLastIndex = this.order.payments.length - 1
      for (let index = 0; index < beforeLastIndex; index++) {
        previousData.sum += this.order.payments[index].payAmount
      }
      return previousData
    }
    if (this.order?.payments.length === 1 && this.order?.payments[0]?.channel === 'CreditNote') {
      previousData.isCreditNote = true
      previousData.sum = this.order?.payments[0]?.payAmount
      return previousData
    }
    return previousData
  }

  get currentPaid (): any {
    return this.order.payments[this.order.payments.length - 1]
  }

  get branchCode (): string {
    return this.branchAddress?.code ?? ''
  }

  get hasBillId (): boolean {
    return this.order?.billId && this.order.billId !== '-'
  }

  get isGW (): boolean {
    return this.storeInfo?.id === this.gwStoreId
  }

  get customerHubUrl (): string {
    // eslint-disable-next-line max-len
    let result = `${process.env.VUE_APP_CUSTOMER_HUB_URL}`
    result += `?sellerId=${process.env.VUE_APP_IBOX_SELLER_ID}`
    result += `&branch_code=${this.order?.branchPos.code}`
    result += `&label=${this.order?.billId}`
    result += `&token=${this.order?.billUrl}`
    result += `&brand=${this.order?.brand}`
    result += `&store_id=${this.order?.store.id}`
    result += `&date=${Number(dayjs(this.order?.createdAt).toDate())}`

    return encodeURI(result)
  }

  get qrText (): string {
    let qrTextDisplay = ''
    if (this.order.iBoxService.eTax && this.order.iBoxService.vrt) {
      qrTextDisplay += 'Scan the QR code to request a VAT refund\n'
      qrTextDisplay += 'document or an e-Tax invoice (submission\n'
      qrTextDisplay += 'must be completed on the purchase date).\n'
      qrTextDisplay += 'Alternatively, use the QR code to share your \n'
      qrTextDisplay += 'feedback with us! \n \n'
      qrTextDisplay += 'สแกน QR โค้ดเพื่อขอ e-Tax Invoice\n'
      qrTextDisplay += `( กรุณาดำเนินการภายในวันที่ ${ dayjs(this.order?.createdAt).add(3, 'day').endOf('day').format('DD-MM-YYYY') } )\n`
      qrTextDisplay += 'หรือเพื่อแสดงความคิดเห็นเกี่ยวกับร้านของเรา'
      return qrTextDisplay
    } if (this.order.iBoxService.eTax) {
      qrTextDisplay += 'Scan the QR code to request a e-Tax invoice \n'
      qrTextDisplay += '(submission must be completed on the purchase date). \n'
      qrTextDisplay += 'Alternatively, use the QR code to share your feedback \n'
      qrTextDisplay += 'with us! \n \n'
      qrTextDisplay += 'สแกน QR โค้ดเพื่อขอ e-Tax Invoice \n'
      qrTextDisplay += `( กรุณาดำเนินการภายในวันที่ ${ dayjs(this.order?.createdAt).add(3, 'day').endOf('day').format('DD-MM-YYYY') } )\n`
      qrTextDisplay += 'หรือเพื่อแสดงความคิดเห็นเกี่ยวกับร้านของเรา'
      return qrTextDisplay
    } if (this.order.iBoxService.vrt) {
      qrTextDisplay += 'Scan the QR code to request a VAT refund document \n'
      qrTextDisplay += '(submission must be completed on the purchase date). \n'
      qrTextDisplay += 'Alternatively, use the QR code to share your feedback \n'
      qrTextDisplay += 'with us! \n \n'
      qrTextDisplay += 'สแกน QR โค้ดเพื่อแสดงความคิดเห็นเกี่ยวกับร้านของเรา \n'
      return qrTextDisplay
    }
    qrTextDisplay += 'Scan the the QR code to share your feedback with us! \n \n'
    qrTextDisplay += 'สแกน QR โค้ดเพื่อแสดงความคิดเห็นเกี่ยวกับร้านของเรา'
    return qrTextDisplay
  }
}
