
import { Component, Vue } from 'vue-property-decorator'
import CustomerInfo from '@/components/desktop-pos/customer-info.vue'
import PriceSummary from '@/components/desktop-pos/price-summary.vue'
import MemberCard from '@/components/desktop-pos/member-card.vue'
import Receipt from '@/components/Receipt.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import DesktopPosNavbar from '@/components/desktop-pos/navbar.vue'

@Component({
    components: {
        CustomerInfo,
        PriceSummary,
        MemberCard,
        Receipt,
        DesktopPosNavbar
    },
    computed: {
        ...mapState({
            selectBranch: (state: any) => state.Store.selectBranch,
            selectPos: (state: any) => state.Store.selectPos
        }),
        ...mapGetters({
            net: 'Cart/net',
            cartTel: 'Cart/tel',
            exchangeTel: 'ExchangeOrder/tel',
            exoNet: 'ExchangeOrder/desktopPayMore'
        })
    },
    methods: {
        ...mapActions({
            previewReceipt: 'Order/previewReceipt',
            createOrder: 'Cart/createOrder',
            createOrderExchange: 'ExchangeOrder/createOrder',
            setCashAmount: 'Cart/setCashAmount',
            setCashAmountExchange: 'ExchangeOrder/setCashAmount',
            previewExchangeReceipt: 'Order/previewExchangeReceipt'
        })
    }
})
export default class CashCheckout extends Vue {
    net!: any

    cartTel!: any

    exchangeTel!: any

    previewReceipt!: any

    previewExchangeReceipt!: any

    preReceipt: any = null

    selectBranch!: any

    selectPos!: any

    showFullPriceFormat!: any

    cashNumber = 0

    createOrder!: any

    createOrderExchange!: any

    setCashAmount!: any

    setCashAmountExchange!: any

    showPriceFormat!: any

    loading = false

    public readonly exoNet!: any

    cashLists = [
      {
        amount: 1000,
        count: 0
      },
      {
        amount: 2000,
        count: 0
      },
      {
        amount: 3000,
        count: 0
      },
      {
        amount: 4000,
        count: 0
      },
      {
        amount: 5000,
        count: 0
      },
      {
        amount: 0,
        count: 0
      }
    ]

    async mounted (): Promise<any> {
      if (this.$route.query?.type === 'exchange-order') {
        this.preReceipt = await this.previewExchangeReceipt({ status: 'no_print', ip: 'no print' })
      } else {
        this.preReceipt = await this.previewReceipt({ status: 'no_print', ip: 'no print' })
      }
    }

    addCash (amount: number): void {
    if (amount === 0) {
      this.cashNumber = 0
      this.cashLists = this.cashLists.map((c) => ({
        ...c,
        count: 0
      }))
    } else {
      this.cashNumber += amount
      const index = this.cashLists.findIndex((c) => c.amount === amount)

      if (index !== -1) {
        this.cashLists[index].count++
      }
    }
  }

  get isExchangeOrder (): boolean {
    return this.$route.query?.type === 'exchange-order'
  }

  async checkout (): Promise<void> {
    this.loading = true
    let result: {
      success: boolean
      orderId: any
      message: string
    }
    if (this.isExchangeOrder) {
      this.setCashAmountExchange(this.cashNumber)
      result = await this.createOrderExchange()
    } else {
      this.setCashAmount(this.cashNumber)
      result = await this.createOrder()
    }
    this.loading = false
    if (result.success) {
      this.$buefy.dialog.confirm({
        message: 'Create Order success',
        canCancel: false,
        onConfirm: () => this.$router.push({ name: 'OrderSuccess', query: { ...this.$route.query, orderId: result.orderId } })
      })
    } else {
      let message = ''
      if (result.message.includes('[00]')) {
        message += 'ของไม่พอ : '
      }
      const splitMessage = result.message.split(' - ')
      message += splitMessage[splitMessage.length - 1]
      message += '<br />กลับไปลบของในตะกร้าออกก่อน หรือ ติดต่อผู้จัดการสาขา'
      this.$buefy.dialog.alert({
        title: 'เกิดปัญหา',
        message
      })
    }
  }

  get sumAmount (): string {
    if (!this.preReceipt) return '-'
    return `${this.preReceipt.items.reduce((sum: number, d: any) => sum + d.amount, 0) }`
  }

  get payNet (): number {
    return this.$route.query?.type === 'exchange-order' ? this.exoNet : this.net ?? 0
  }

  goBack (): void {
    this.$router.push({ name: 'PaymentChannel', query: this.$route.query ?? {} })
  }

  get memberTel (): string {
    return this.isExchangeOrder ? this.exchangeTel : this.cartTel
  }
}
